.new_test_button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.std_assesment_test{
    display: flex;
    flex-direction: column;
    
}