.login_container {
    background-image: url("../../admin/login/admin-background-pic.jpg");
    width: 100%;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    .login_content {
        .login_image {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            border: 1px solid #fff;
            box-shadow: 0 2px 9px rgb(0 0 0 / 24%);
            overflow: hidden;
            margin: 0 auto;
            img {
                vertical-align: middle;
                border-style: none;
                width: 100%;
            }
        }
        .company_name {
            padding-top: 10px;
            font-family: Montserrat-ExtraBold;
            font-size: 24px;
            color: #fff;
            line-height: 1.2;
            text-align: center;
            width: 100%;
        }
        .field_container {
            margin-top: 30px;
            background-color: white;
            border-radius: 25px;
            height: 50px;
            width: 350px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .textfield {
                width: 90%;
            }
        }
        .login_btn {
            margin-top: 20px;
            cursor: pointer;
            font-size: 15px;
            line-height: 1.5;
            color: #e0e0e0;
            height: 50px;
            border-radius: 25px;
            background: #333333;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 25px;
            -webkit-transition: all 0.4s;
            -o-transition: all 0.4s;
            -moz-transition: all 0.4s;
            transition: all 0.4s;
            position: relative;
            z-index: 1;
            font-weight: bold;
            &::before {
                content: "";
                display: block;
                position: absolute;
                z-index: -1;
                width: 350px;
                height: 100%;
                border-radius: 25px;
                top: 0;
                left: 0;
                background-image: -webkit-linear-gradient(left, rgb(0, 91, 234), rgb(0, 198, 251));
                -webkit-transition: all 0.4s;
                -o-transition: all 0.4s;
                -moz-transition: all 0.4s;
                transition: all 0.4s;
                opacity: 0;
            }
            &:hover {
                background: transparent;
                color: #fff;
                &::before {
                    opacity: 1;
                }
            }
        }
    }
    &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: -webkit-linear-gradient(bottom, #005bea, #00c6fb);
        opacity: 0.9;
    }
}

.test {
    color: #00c6fb;
    margin-left: -10px;
}
.test2 {
    margin-left: 10px;
}
