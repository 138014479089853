.admin_container {
    display: flex;
    flex-direction: row;
    .admin_body_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-top: 10px;
    }
    .left_side_panel_container {
        display: flex;
        flex-direction: column;
        width: 250px;
        min-height: 100vh;
        background-color: rgb(50, 49, 60);
        .company_name {
            padding-top: 20px;
            margin: 0 auto;
            .b_color {
                color: rgb(50, 176, 214);
                font-size: 30px;
            }
            .w_color {
                color: rgb(255, 253, 255);
                font-size: 30px;
            }
        }
        .manage_container {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .item {
                width: 100%;
                height: 70px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                align-content: center;
                align-self: center;
                text-decoration: unset !important;
                .icon_color {
                    color: rgb(175, 179, 180);
                    margin-left: 10%;
                }
                .label {
                    color: rgb(175, 179, 180);
                    width: 80%;
                    margin-left: 5%;
                }
                &:hover {
                    .icon_color {
                        color: rgb(242, 253, 255);
                    }
                    .label {
                        color: rgb(242, 253, 255);
                    }
                    color: rgb(242, 253, 255);
                    cursor: pointer;
                    background-color: rgb(45, 174, 203);
                }
            }
            .item_active {
                width: 100%;
                height: 70px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                align-content: center;
                align-self: center;
                text-decoration: unset !important;
                background-color: rgb(45, 174, 203);
                .icon_color {
                    color: rgb(242, 253, 255);
                    margin-left: 10%;
                }
                .label {
                    color: rgb(242, 253, 255);
                    width: 80%;
                    margin-left: 5%;
                }
            }
        }
    }
}
