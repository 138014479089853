.footer_container {
    display: flex;
    flex-direction: row;
    background-color: aquamarine;
    padding-left: 70px;
    background-color: rgb(0, 68, 136);
    margin-top: 10px;
    .left_panel {
        display: flex;
        flex-direction: column;
        width: 10%;
        text-align: center;
        margin-top: 10px;
        padding-bottom: 20px;
        justify-content: space-between;
        .social_icon {
            color: white;
            font-size: 30px;
            cursor: pointer;
        }
    }
    .right_panel {
        display: flex;
        flex-direction: column;
        width: 90%;
        background-color: rgb(0, 76, 151);
        padding-bottom: 30px;
        padding-left: 30px;
        .company_name {
            text-transform: uppercase;
            font-family: calluna, serif;
            font-weight: 300;
            font-size: 1.375rem;
            line-height: 1.1364;
            color: white;
            .details {
                margin-top: 40px;
            }
        }
    }
    .fixed_logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 80px;
        right: 20px;
        z-index: 25;
        .whatsapp_container {
            box-shadow: 0 2px 9px rgb(0 0 0 / 24%);
            background-color: green;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            .whatsapp {
                color: #fff;
                font-size: 30px;
            }
        }
    }
}
.lang {
    font-size: 15px;
    font-weight: bold;
    color: white;
}
@media only screen and (max-width: 768px) {
    .footer_container {
        padding-left: 20px;
    }
}

@media only screen and (max-width: 450px) {
    .footer_container {
        flex-direction: column;
        padding-left: 20px;
        .left_panel {
            padding-left: unset;
            flex-direction: row;
            width: 90%;
        }
        .right_panel {
            padding-left: 15px;
        }
    }
}
