.flex_col {
    display: flex;
    flex-direction: column !important;
}.flex_row {
    display: flex;
    flex-direction: row !important;
}
.flex_row_center_center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.flex_col_center_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cursor_pointer {
    cursor: pointer;
}
.divider_dash_white {
    border: 1px dashed #fff;
    margin-top: 5px;
    margin-bottom: 5px;
}

@keyframes slide2 {
    100% {
        transform: rotate(-180deg);
    }
}
@keyframes slide3 {
    100% {
        transform: rotate(0deg);
    }
}
.rotate {
    animation: slide2 1s forwards;
}
.rotate2 {
    transform: rotate(-180deg);
    animation: slide3 1s forwards;
}

.border_left_blue {
    padding-left: 15px;
    border-left: 5px solid #2daecb;
}
.divider {
    background-color: #2daecb;
    width: 90%;
    height: 1px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.gray_text_field {
    width: 90%;
    border: 1px solid #cccccc !important;
    border-radius: 5px;
    padding-left: 10px;
}

.modal_container {
    background-color: white;
    width: 450px;
    margin: 0 auto;
    margin-top: 20%;
    padding: 10px;
    .title {
        margin-left: 20px;
    }
}

.background_second {
    background-color: rgba(45, 174, 203, 0.2);
}
.p_l_20{
    padding-left: 20px;
}
.close_video{
    display: flex;
    color: blue;
    cursor: pointer;
    background-color: white;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 10px;
}
.h_70px{
    height: 70px!important;
}