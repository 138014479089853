.slideshow_3d {
  margin: 0 auto;
  margin-top: 50px;
  padding-top: 50px;
  height: 450px;
  perspective: 1000px;
}
.content {
  margin: auto;
  width: 150px;
  perspective: 1000px;
  position: relative;
  padding-top: 80px;
  transform-style: preserve-3d;
}
.people_opinion{
  background-color: blue;
}
.slider-content {
  width: 100%;
  position: absolute;
  float: right;
  animation: rotate 60s infinite linear;
  transform-style: preserve-3d;
}
.slider-content:hover {
  cursor: pointer;
  animation-play-state: paused;
}

.slider-content div {
  width: 180px;
  height: 120px;
  border: 1px solid #555;
  overflow: hidden;
  position: absolute;
}
.slider-content div:nth-child(1) {
  transform: rotateY(0deg) translateZ(300px);
}

.slider-content div:nth-child(2) {
  transform: rotateY(40deg) translateZ(300px);
}
.slider-content div:nth-child(3) {
  transform: rotateY(80deg) translateZ(300px);
}
.slider-content div:nth-child(4) {
  transform: rotateY(120deg) translateZ(300px);
}
.slider-content div:nth-child(5) {
  transform: rotateY(160deg) translateZ(300px);
}
.slider-content div:nth-child(6) {
  transform: rotateY(200deg) translateZ(300px);
}
.slider-content div:nth-child(7) {
  transform: rotateY(240deg) translateZ(300px);
}
.slider-content div:nth-child(8) {
  transform: rotateY(280deg) translateZ(300px);
}
.slider-content div:nth-child(9) {
  transform: rotateY(320deg) translateZ(300px);
}

.shadow {
  position: absolute;
  box-shadow: 0px 0px 0px #000;
}
.slider-content img {
  image-rendering: auto;
  transition: all 300ms;
  width: 100%;
  height: 100%;
}
.slider-content img:hover {
  transform: scale(1.2);
  transition: all 300ms;
}




.slider-content2 {
  width: 100%;
  position: absolute;
  float: right;
  animation: rotate 60s infinite linear;
  transform-style: preserve-3d;
}
.slider-content2:hover {
  cursor: pointer;
  animation-play-state: paused;
}

.slider-content2 div {
  width: 150px;
  height: 200px;
  border: 1px solid #555;
  overflow: hidden;
  position: absolute;
}
.slider-content2 div:nth-child(1) {
  transform: rotateY(0deg) translateZ(300px);
}

.slider-content2 div:nth-child(2) {
  transform: rotateY(40deg) translateZ(300px);
}
.slider-content2 div:nth-child(3) {
  transform: rotateY(80deg) translateZ(300px);
}
.slider-content2 div:nth-child(4) {
  transform: rotateY(120deg) translateZ(300px);
}
.slider-content2 div:nth-child(5) {
  transform: rotateY(160deg) translateZ(300px);
}
.slider-content2 div:nth-child(6) {
  transform: rotateY(200deg) translateZ(300px);
}
.slider-content2 div:nth-child(7) {
  transform: rotateY(240deg) translateZ(300px);
}
.slider-content2 div:nth-child(8) {
  transform: rotateY(280deg) translateZ(300px);
}
.slider-content2 div:nth-child(9) {
  transform: rotateY(320deg) translateZ(300px);
}
.slider-content2 img {
  image-rendering: auto;
  transition: all 300ms;
  width: 100%;
  height: 100%;
}
.slider-content2 img:hover {
  transform: scale(1.2);
  transition: all 300ms;
}

@keyframes rotate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .slideshow_3d {
    perspective: unset;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

.slider_title {
  position: absolute;
  color: orange;
  background: #00000080;
  top:  50%;
  left: 0;
  width: 100%;
  text-align: center;
}