.swipeable_drawer_mobile_container {
    width: 100vh;
    height: 100vh;
    background-color: #004c97;
    .header_container {
        display: grid;
        grid-template-columns: 10% 90%;
        height: 77px;
        border-bottom: 1px dashed #9da2a6;
        .close_drawer {
            width: 100%;
            background-color: white;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            text-transform: uppercase;
            font-size: 0.75rem;
            /* cursor: pointer; */
            height: 77px;
            text-transform: uppercase;
            font-size: 0.75rem;
            line-height: 1.1667;
            font-weight: 700;
            .close_icon {
                font-size: 1.9rem;
                color: #004c97;
            }
        }
        .right_drawer {
            width: 100%;
        }
    }
    .menues_container {
        display: flex;
        flex-direction: column;
        padding-left: 3%;
        margin-top: 10px;
        div {
            .link {
                color: white;
                text-decoration: unset;
            }
            color: white;
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            padding-bottom: 15px;
            margin-bottom: 15px;
            width: 42%;
            text-transform: capitalize;
            font-size: 1.2375rem;
            line-height: 1.3044;
            font-family: calluna, serif;
            font-weight: 400;
        }
    }
}

.swipeable_drawer_container {
    width: 50vh;
    height: 100vh;
    background-color: #004c97;
    .header_container {
        display: grid;
        grid-template-columns: 20% 80%;
        height: 77px;
        border-bottom: 1px dashed #9da2a6;
        .close_drawer {
            width: 100%;
            background-color: white;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            text-transform: uppercase;
            font-size: 0.75rem;
            /* cursor: pointer; */
            height: 77px;
            text-transform: uppercase;
            font-size: 0.75rem;
            line-height: 1.1667;
            font-weight: 700;
            .close_icon {
                font-size: 1.9rem;
                color: #004c97;
            }
        }
        .right_drawer {
            width: 100%;
        }
    }
    .menues_container {
        display: flex;
        flex-direction: column;
        padding-left: 3%;
        margin-top: 10px;
        div {
            .link {
                color: white;
                text-decoration: unset;
            }
            color: white;
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            padding-bottom: 15px;
            margin-bottom: 15px;
            width: 90%;
            text-transform: capitalize;
            font-size: 1.4375rem;
            line-height: 1.3044;
            font-family: calluna, serif;
            font-weight: 400;
        }
    }
}
