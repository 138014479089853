@media only screen and (max-width: 768px) {
  .slideshow {
    .slideshowSlider {
      .slide {
        img {
          height: 250px!important;
        }
      }
    }
  }
}

/* Slideshow */
.slideshowAny {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
  cursor: pointer;
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 20000ms;
    height: 100%;
    width: 100%;
    .slide {
      display: inline-block;
      height: 100%;
      border-radius: 40px;
    }
  }
}
.native{
  width: 100%;
  height: 100%;
}