@media only screen and (max-width: 768px) {
    .paragraph_container {
        padding: 46px 20px 30px !important;
        padding-bottom: 10px!important;
        padding-top: 30px!important;
        .title {
            font-size: 22px !important;
        }
        .body {
            font-size: 18px !important;
        }
    }
}

.paragraph_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 70px;
    padding-bottom: 10px;
    padding-top: 40px;
    .title {
        color: #004c97;
        font-size: 28px;
        font-family: calluna-sans, sans-serif;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .body {
        display: block;
        font-family: calluna, serif;
        font-weight: 300;
        color: #693f23;
        font-size: 1.3125rem;
    }
}
.rtl{
    direction: rtl;
}
