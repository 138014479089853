.sub_slider_container{
    padding-top: 50px;
}
.item_order{
    padding-left: 50px!important;
}
.form-control{
    display: flex;
    flex-direction: column;
    .partition{
        color: #23a9ca!important;
        transform: translate(0, 1.5px) scale(0.99) !important;
    }
}
.error_border{
    border: 1px solid red!important;
}