@media only screen and (max-width: 768px) {
  .slideshow {
    .slideshowSlider {
      .slide {
        img {
          height: 250px!important;
        }
      }
    }
  }
}

/* Slideshow */
.slideshow {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    height: 100%;
    width: 100%;
    .slide {
      display: inline-block;
      height: 100%;
      width: 100%;
      border-radius: 40px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  .slideshowDots_mobile {
    text-align: center;
    background-color: #6a0dad;
    position: absolute;
    background: #ffffff;
    z-index: 5;
    bottom: 0px;
    right: 0px;
    width: 20%;
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .slideshowDot {
      display: inline-block;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      cursor: pointer;
      background-color: #39434d;
    }
    .slideshowDot.active {
      background-color: #693f23;
      height: 20px;
      width: 20px;
    }
    .slide_arrow {
      font-size: 2rem !important;
      color: #b8bfc5;
      cursor: pointer;
    }
  }
  /* Buttons */
  .slideshowDots {
    text-align: center;
    background-color: #6a0dad;
    position: absolute;
    background: #ffffff;
    z-index: 5;
    bottom: 0px;
    right: 0px;
    width: 314px;
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .slideshowDot {
      display: inline-block;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      cursor: pointer;
      background-color: #39434d;
    }
    .slideshowDot.active {
      background-color: #693f23;
      height: 15px;
      width: 15px;
    }
    .slide_arrow {
      font-size: 2rem !important;
      color: #b8bfc5;
      cursor: pointer;
    }
  }
}
.native{
  width: 100%;
  height: 100%;
}