.Pagination_container {
    margin-bottom: 20px;
    margin-top: 20px;
    .page {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        transition: background-color 0.3s;
        border: 1px solid #ddd;
        margin: 0 4px;
        &:hover:not(.active) {
            background-color: #ddd;
        }
    }
    .active {
        background-color: #4caf50;
        color: white;
        border: 1px solid #4caf50;
    }
    .hidden{
        display: none; 
    }
}