@import "../../common.scss";
.mobile_header_container {
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 20% 80%;
    background: white;
    .menu_burger_container {
        border: 0;
        background: none;
        margin: 0;
        padding: 0;
        border-bottom: 1px dashed #9da2a6;
        color: #004c97;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.75rem;
        line-height: 1.1667;
        font-weight: 700;
        cursor: pointer;
        width: 100%;
        height: 77px;
        -webkit-transition: 0.3s color;
        transition: 0.3s color;
        .menu_icon {
            font-size: 2rem;
        }
    }
    .mobile_header_company_name {
        background-color: #004c97;
        color: #fff;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .logo {
            height: 75px;
        }
    }
}
