.new-assessment-test-Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .assessment-form-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-top: 50px;
        img{
            width: 150px;
            border-bottom-left-radius: 20%;
            border-top-right-radius: 20%;
            padding-bottom: 15px;
        }
    }
}