@import "../../../common.scss";
.partition{
    color:rgb(35,169,202);
    font-size: 15px;
    margin-top: 0;
    padding-bottom: 10px;
    span{
        color: red;
        margin-left: 10px;
        font-size: 12px;
    }
}
.error{
    border: 1px solid red!important;
}
