@import "../../common.scss";
.left_side_container {
    background-color: #fff;
    grid-template-rows: 10% 80% 10%;
    height: 100vh;
    width: 69px;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    z-index: 16;
    .menu_container {
        border-bottom: 1px dashed #9da2a6;
        color: #39434d;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.75rem;
        line-height: 1.1667;
        font-weight: 700;
        cursor: pointer;
        -webkit-transition: 0.3s all;
        transition: 0.3s all;
        .menu_icon_burger {
            font-size: 2rem;
            color: #004c97;
        }
        &:hover {
            color: #fff;
            background: #004c97;
            .menu_icon_burger {
                color: #fff;
            }
        }
    }
    .news_container {
        transform: translateX(-50%) rotate(-90deg);
        top: calc(50% - 15px);
        left: 50%;
        max-width: 69px;
        .text_rotate {
            border: 0;
            background: none;
            margin: 0;
            padding: 0;
            padding-bottom: 8px;
            position: relative;
            color: #007a53;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            text-transform: uppercase;
            font-size: 1.125rem;
            line-height: 1.2223;
            font-weight: 900;
            cursor: pointer;
            transition: 0.3s color;
            margin-top: 100%;
            white-space: nowrap;
            max-width: 100%;
            text-overflow: unset;
            .arrow_icon {
                margin-left: 10px;
                margin-top: 6px;
            }
            .line {
                content: "";
                position: absolute;
                bottom: 0;
                left: 110%;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
                width: 50%;
                height: 2px;
                background: #007a53;
                -webkit-transition: 0.3s width;
                transition: 0.3s width;
            }
            &:hover {
                .line {
                    width: 240%;
                }
            }
        }
    }
    @keyframes bounce {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-5px);
        }
        100% {
            transform: translateY(0);
        }
    }
    .eplore_container {
        border: 0;
        background: none;
        margin: 0;
        padding: 0;
        border-top: 1px solid #9da2a6;
        padding: 30px 0;
        width: 69px;
        display: block;
        font-size: 0.75rem;
        line-height: 1.1667;
        text-transform: uppercase;
        font-weight: 700;
        color: #39434d;
        text-align: center;
        cursor: pointer;
        -webkit-transition: 0.3s color;
        transition: 0.3s color;
        .arrow {
            margin-top: 10px;
        }
        &:hover {
            .arrow {
                -webkit-animation: 1s bounce;
                animation: 1s bounce;
            }
        }
    }
}
