.comment_icon {
    color: green;
    cursor: pointer;
}
.message_container {
    display: flex;
    flex-direction: column;
    .text{
        font-weight: bold;
    }
    .message {
        max-height: 250px;
        border: 1px solid;
        height: 100%;
        overflow: auto;
        box-sizing: border-box;
    }
}
