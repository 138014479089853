@media only screen and (max-width: 768px) {
    .article_image_show {
        width: 50% !important;
        height: 50% !important;
        margin: 0 auto;
    }
}

.article_main_container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    background-color: #004c97;
    height: 91px;
    .article_company_name {
        padding: 24px;
        color: #fff;
        font-size: 1.8125rem;
        line-height: 1.1539;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }
}

.sub_article_container {
    display: flex;
    flex-direction: column;
    min-height: 70vh;
    .image {
        width: 50%;
        height: 50%;
        margin: 0 auto;
        margin-top: 20px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .created_at {
        margin: 0 auto;
        color: gray;
    }
}

.article_slide_show_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #004488;
    height: 400px;

}
