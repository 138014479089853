.admin_settings_container {
    display: flex;
    flex-direction: column;
    width: 97%;
    height: 97%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    .header_container {
        display: flex;
        flex-direction: row;
        padding-bottom: 30px;
        .settings_text {
            font-size: 20px;
            margin-top: 0;
        }
    }
    .body{
        padding: 20px;
    }
}