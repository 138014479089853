
.upload_button{
    border-style: groove;
}
.display_table{
    display: table;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: table;
}
.sub_courses_container{
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    width: 97%;
    height: 97%;
}
.is_sub_course_button{
    height: 22px;
}
.item{
    padding-left: 25px!important;
}