@page {
    size: A4;
    margin: 0;
}
.main_report_container {
    display: flex;
    flex-direction: column;
    width: 210mm;
    height: 297mm;
    // background-color: yellow;
    .report_header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: white;
        height: 150px;
        font-size: 30px;
        background-color: rgb(44, 59, 88);
        padding-top: 30px;
        .report_logo {
            width: 18%;
            height: 100%;
        }
    }

    .orang_c {
        width: 100%;
        height: 10px;
        background-color: rgb(134, 93, 75);
        z-index: 2;
    }
    .report_header_string {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .report_header_name {
            font-size: 15px;
            font-weight: bold;
        }
    }
    .report_desc {
        // padding-top: 10px;
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        font-size: 20px;
    }
    .bold {
        font-weight: bold;
    }
    .report1 {
        // padding-top: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 30px;
    }
    .report_fotter {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 113px;
        margin-top: 30px;
        background-color: rgb(44, 59, 88);
        color: white;
        .orang_line {
            width: 100%;
            height: 10px;
            background-color: #865d4b;
        }
        .fotter_body {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
            .fotter_location {
                width: 40%;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                margin-left: 25px;
            }
            .fotter_web_site {
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                padding: 3px;
            }
            .fotter_line {
                height: 100%;
                width: 5px;
                background-color: #865d4b;
            }
        }
    }
}
