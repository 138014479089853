.course_image {
    height: 60vh;
    img {
        margin-top: 10px;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.sub_course_book_now_container {
padding-top: 20px;
}

