.menus_table_container {
    display: flex;
    flex-direction: column;
    border-style: groove;
    .maenus_header_container {
        display: flex;
        flex-direction: row;
        background-color: #2daecb;
        //margin-right: 10px;
        .item_header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            /* padding-left: 25px; */
           
            width: 100%;
            height: 30px;
        }
    }
    .item_container {
        display: flex;
        flex-direction: row;
        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            /* padding-left: 25px; */
            width: 100%;
            height: 30px;
            .edit_icon{
                color: green;
            }
            .delete_icon{
                color: red;
            }
        }
    }
}
.modal_container{
    background-color: white;
    .update_now_button{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 5px;
    }
}
.green_button{
    color: green;
}
.red_button{
    color: red;
    
}
.new_menu_button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}