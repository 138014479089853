@media only screen and (max-width: 5000px) {
.book_test_form_container {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
    .book_container {
        background-color: #004c97;
        width: 80%;
        justify-content: center;
        align-items: center;
        .book_now {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: beige;
            padding-top: 1%;
            padding-left: 5%;
            padding-bottom: 2%;
            font-weight: bolder;
            font-style: oblique;
        }
    }
    .filed {
        margin-top: 10px;
        width: 70%;
        background-color: white;
        .textfield {
            width: 100%;
           
        }
    }
}
}
@media only screen and (max-width: 450px) {
.book_test_form_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .book_container {
        background-color: #004c97;
        width: 80%;
        justify-content: center;
        align-items: center;
        .book_now {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: beige;
            padding-top: 1%;
            padding-left: 5%;
            padding-bottom: 2%;
            font-weight: bolder;
            font-style: oblique;
        }
    }
    .filed {
        margin-top: 10px;
        width: 70%;
        background-color: white;
        .textfield {
            width: 100%;
        }
    }
}
}
.full_w{
    width: 100% !important;
    }