.image_course_container {
    padding: 20px;
    .course {
        width: 98%;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        &:hover {
            img {
                transform: scale(1.3);
            }
            .info {
                .desc {
                    display: block;
                    max-height: 210px;
                    overflow-y: hidden;
                }
            }
        }
        img {
            height: 585px;
            width: 100%;
            transition: transform 0.4s; /* smoother zoom */
            object-fit: cover;
        }
        .info {
            position: absolute;
            bottom: 4px;
            left: 10%;
            width: 70%;
            padding: 20px;
            .name {
                text-align: center;
                color: #fff;
                font-size: 1.0625rem;
                line-height: 1.1765;
                text-transform: uppercase;
                font-weight: bold;
            }
            .desc {
              
                 display: none;
                 text-align: left;
                 color: #fff;
                 font-size: 1.0625rem;
                 line-height: 1.1765;
                
            }
        }
    }
}
.rtl{
    direction: rtl;
}