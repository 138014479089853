@media only screen and (max-width: 768px) {
    .main_container {
        .children {
            padding-left: unset !important;
        }
    }
}
.main_container {
    .children {
        padding-left: 70px;
    }
}
