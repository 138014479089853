.contact_us {
    padding-left: 20px;
    margin-bottom: 50px;
    margin-top: 120px
}
@media only screen and (max-width: 768px) {
    .contact_us {
        padding-left: 1px;
        margin-bottom: 20px;
        margin-top: 20px
    }
}

