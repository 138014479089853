@keyframes slideToRigth {
    100% {
        left: 0;
    }
}
@keyframes slideToLeft {
    100% {
        left: -100%;
    }
}
.article_drawer_container {
    height: 100%;
    width: 100%;
    position: fixed;
    left: -100%;
    z-index: 15;
    background: rgba(0, 76, 151, 0.95) ;
    .article_container{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 90%;
        padding-left: 7%;
    }

}

.article_drawer_show {
    animation: slideToRigth 1s forwards;
}
.article_drawer_hide {
    left: 0;
    animation: slideToLeft 1s forwards;
}
