@media only screen and (max-width: 768px) {
    .objectives_slide_show {
        width: 90%!important;
        height: 90%!important;
        margin: 0 auto;
    }
}

.objectives_main_container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    background-color: #004c97;
    height: 91px;
    .company_name {
        padding: 24px;
        color: #fff;
        font-size: 1.8125rem;
        line-height: 1.1539;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }
}
.objectives_slide_show {
    width: 50%;
    height: 50%;
    margin: 0 auto;
    margin-top: 20px;
}
.sub_course_container{
    
}
