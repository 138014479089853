.company_name_container {
    width: 100%;
    display: flex;
    flex-direction: row;

    .text_field {
        width: 100%;
        padding-top: 20px;
        box-shadow: 0 8px 6px -6px black;
    }
}
.text_fields {
    display: flex;
    flex-direction: column;
    // width: 30%;
    padding-top: 20px;
    box-shadow: 0 8px 6px -6px black;
    .field {
        text-decoration: unset !important;
    }
}
.update_button {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    padding-top: 50px;
}
.input_container {
    position: relative;
    .delet_from_array_icon {
        color: red;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid red;
        cursor: pointer;
        position: absolute;
        right: 11%;
    }
}

.add_number_button {
    padding-top: 10px;
}
.add_number_button_modal{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
