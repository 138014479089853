.test_form_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    .book_container {
        background-color: #004c97;
        width: 80%;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        .test_clock {
            width: 150px;
            height: 150px;
            margin-top: 20px;
        }
        .book_now {
            display: flex;
            flex-direction: column;
            color: beige;
            padding-top: 1%;
            padding-left: 5%;
            padding-bottom: 2%;
            font-weight: bold;
            font-style: normal;
            font-size: 22px;
        }
    }
    .filed {
        margin-top: 10px;
        width: 70%;
        background-color: white;
        font-weight: bold;
        font-size: 23px;
        border-bottom: 2px dashed;
        padding-bottom: 10px;
        cursor: pointer;
        .textfield {
            width: 100%;
        }
        &.active {
            background-color: #004c97;
            color: white;
        }
    }
}
@media only screen and (max-width: 450px) {
    .test_form_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
        .book_container {
            background-color: #004c97;
            width: 80%;

            .book_now {
                display: flex;
                flex-direction: column;

                color: beige;
                padding-top: 1%;
                padding-left: 5%;
                padding-bottom: 2%;
                font-weight: bolder;
                font-style: oblique;
            }
        }
        .filed {
            margin-top: 10px;
            width: 70%;
            background-color: white;
            .textfield {
                width: 100%;
            }
        }
    }
}
.hint {
    padding-top: 15px;
    color: green;
    width: 50%;
}
.next_button {
    margin-top: 15px;
}
